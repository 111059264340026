/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FileData } from 'hooks/useFileDownloader';
import { baseResponse } from 'interfaces/BaseResponse';
import {
  cellphoneValidate,
  completeProposals,
  cpfValidate,
  editFullProposal,
  editProposalResponse,
  filterValues,
  listQueryParams,
  payloadCreateProposal,
  proposal,
  proposalListCsv as proposalListCsvExport,
  proposalByIdentifierResponse,
  proposalSignatureStatusChange,
  proposalStatusChangeEvent,
  putProposalCancellationPayload,
  responseValidationCelular,
  responseVileveAssistenciaProductsById,
  statusHistoryProposal,
  validateCellPhoneinterface,
  validateCPFinterface,
  responseHasDocumentSignature,
} from 'interfaces/Proposal';
import { errorRedux, reduxTemplate } from 'interfaces/redux';
import { defaultResponse } from 'interfaces/defaultResponse';
import {
  getCellPhoneValidate,
  getCheckNumberBenefit,
  getCpfValidtoProduct,
  getDocumentSignatureStatus,
  getProposalByIdentifier,
  getProposalHistory,
  getProposalList,
  getProposalListCsv,
  getValidateCelular,
  postNewProposal,
  postProposalIntegration,
  putProposal,
  putProposalCancellation,
} from './service';

export const proposalList = createAsyncThunk<
  proposal,
  { payload: listQueryParams; bodyParams?: filterValues },
  { rejectValue: errorRedux }
>('proposal/list', async ({ payload, bodyParams }, { rejectWithValue }) => {
  try {
    const data = await getProposalList(payload, bodyParams);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});
export const proposalListCsv = createAsyncThunk<
  FileData,
  { payload: proposalListCsvExport; bodyParams?: Omit<filterValues, 'startDate' | 'endDate'> },
  { rejectValue: errorRedux }
>('proposal/list', async ({ payload, bodyParams }, { rejectWithValue }) => {
  try {
    const data = await getProposalListCsv(payload, bodyParams);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const editProposal = createAsyncThunk<editProposalResponse, editFullProposal, { rejectValue: reduxTemplate }>(
  'proposal/fullProposal',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await putProposal(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const proposalByIdentifier = createAsyncThunk<
  proposalByIdentifierResponse,
  string,
  { rejectValue: reduxTemplate }
>('proposal/adm/byIdentifier', async (searchAdvanced, { rejectWithValue }) => {
  try {
    const data = await getProposalByIdentifier(searchAdvanced);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const proposalHistory = createAsyncThunk<statusHistoryProposal, string, { rejectValue: reduxTemplate }>(
  '/proposal/statusHistory/identifier',
  async (identifier, { rejectWithValue }) => {
    try {
      const data = await getProposalHistory(identifier);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const validateCelular = createAsyncThunk<responseValidationCelular, string, { rejectValue: reduxTemplate }>(
  'proposal/celularvalidate/celular',
  async (celular, { rejectWithValue }) => {
    try {
      const data = await getValidateCelular(celular);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const validateCheckNumberBenefit = createAsyncThunk<defaultResponse, string, { rejectValue: errorRedux }>(
  '/proposal/checkNumberBenefit/:numberBenefit',
  async (numberBenefit, { rejectWithValue }) => {
    try {
      const data = await getCheckNumberBenefit(numberBenefit);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const proposalIntegrationByIdentifier = createAsyncThunk<baseResponse, string, { rejectValue: reduxTemplate }>(
  'proposal/integration',
  async (identifier, { rejectWithValue }) => {
    try {
      const data = await postProposalIntegration(identifier);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const createProposal = createAsyncThunk<proposal, payloadCreateProposal, { rejectValue: reduxTemplate }>(
  'proposal/create',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await postNewProposal(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const validateCpfProposal = createAsyncThunk<cpfValidate, validateCPFinterface, { rejectValue: reduxTemplate }>(
  'proposal/cpfvalidate',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getCpfValidtoProduct(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const cancellateProposal = createAsyncThunk<
  baseResponse,
  { identifier: string; payload: putProposalCancellationPayload },
  { rejectValue: reduxTemplate }
>('proposal/cancellateProposal', async ({ identifier, payload }, { rejectWithValue }) => {
  try {
    const data = await putProposalCancellation(identifier, payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const checkSignedDocument = createAsyncThunk<
  responseHasDocumentSignature,
  { identifier: string },
  { rejectValue: reduxTemplate }
>('proposal/checkSignedDocument', async (payload, { rejectWithValue }) => {
  try {
    const data = await getDocumentSignatureStatus(payload.identifier);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const validateCellPhoneProposal = createAsyncThunk<
  cellphoneValidate,
  validateCellPhoneinterface,
  { rejectValue: reduxTemplate }
>('proposal/cellphoneValidate', async (payload, { rejectWithValue }) => {
  try {
    const data = await getCellPhoneValidate(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

interface IProposalReports extends reduxTemplate {
  proposalList: proposal;
  completeProposals: completeProposals;
  proposalHistory?: statusHistoryProposal['document'];
  validateCelular?: responseValidationCelular;
  products: responseVileveAssistenciaProductsById;
  createNewProposal: proposal;
  proposalCancellation: baseResponse;
  asyncCpfValidate: boolean;
  asyncCellPhoneValidate: boolean;
  hasSignedDocument: responseHasDocumentSignature;
}

const initialState: IProposalReports = {
  status: 'idle',
  message: '',
  asyncCpfValidate: false,
  asyncCellPhoneValidate: false,
  proposalCancellation: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  proposalList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  completeProposals: {
    status: '',
    message: '',
    proposals: [],
  },
  validateCelular: {
    status: '',
    message: '',
    document: [],
  },
  createNewProposal: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },

  products: {
    status: '',
    message: '',
    document: [],
    data: {
      codigoProduto: 0,
      nome: '',
      bank: [],
    },
    rowsAffected: [],
  },
  hasSignedDocument: {
    data: { isSigned: false },
    document: [],
    message: '',
    rowsAffected: [],
    status: '',
  },
};

export const ProposalStatusReducer = createSlice({
  name: 'ProposalStatus',
  initialState,
  reducers: {
    asyncCpfValidate: (state, action: PayloadAction<boolean>) => {
      state.asyncCpfValidate = action.payload;
    },
    asyncCellPhoneValidate: (state, action: PayloadAction<boolean>) => {
      state.asyncCellPhoneValidate = action.payload;
    },
    updateProposalStatus: (state, action: PayloadAction<proposalStatusChangeEvent>) => {
      // prettier-ignore
      const { payload: { identifier, status } } = action;
      const listProposal = state.proposalList.document.find((_proposal) => {
        return _proposal.identificador === identifier;
      });
      const completeProposal = state.completeProposals.proposals.find((_proposal) => {
        return _proposal.proposal.identifier === identifier;
      });
      if (listProposal) {
        listProposal.status = status;
      }
      if (completeProposal) {
        completeProposal.proposal.status = status;
      }
    },
    updateSignersStatus: (state, action: PayloadAction<proposalSignatureStatusChange>) => {
      const {
        payload: { identificador, status_associacao, status_cliente, status_empresa, status_federacao },
      } = action;

      const currentProposal = state.completeProposals.proposals.find((prop) => {
        return prop.proposal.identifier === identificador;
      });

      if (currentProposal?.sign) {
        currentProposal.sign = {
          ...currentProposal.sign,
          associationStatus: status_associacao,
          enterpriseStatus: status_empresa,
          clientStatus: status_cliente,
          federationStatus: status_federacao,
        };
      }
    },
    clearProposalStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(proposalList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(proposalList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.proposalList = action.payload;
      })
      .addCase(proposalList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(proposalByIdentifier.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(proposalByIdentifier.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        const { proposals } = state.completeProposals;
        const fetchedProposal = action.payload.data;
        const index = proposals.findIndex((e) => {
          return e.proposal.identifier === fetchedProposal.proposal.identifier;
        });
        if (index === -1) {
          proposals.push(fetchedProposal);
          return;
        }
        proposals[index] = fetchedProposal;
      })
      .addCase(proposalByIdentifier.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(proposalHistory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(proposalHistory.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.proposalHistory = action.payload?.document;
      })
      .addCase(proposalHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })

      .addCase(validateCelular.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(validateCelular.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.validateCelular = action.payload;
      })
      .addCase(validateCelular.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(createProposal.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createProposal.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.createNewProposal = action.payload;
      })
      .addCase(createProposal.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(cancellateProposal.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(cancellateProposal.fulfilled, (state, action) => {
        state.status = 'completed';
        state.proposalCancellation = action.payload;
        updateProposalStatus({ identifier: action.meta.arg.identifier, status: 'CANCELADA' });
      })
      .addCase(cancellateProposal.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(checkSignedDocument.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(checkSignedDocument.fulfilled, (state, action) => {
        state.status = 'completed';
        state.hasSignedDocument = action.payload;
      })
      .addCase(checkSignedDocument.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});
export const {
  clearProposalStatus,
  updateProposalStatus,
  updateSignersStatus,
  asyncCpfValidate,
  asyncCellPhoneValidate,
} = ProposalStatusReducer.actions;
export default ProposalStatusReducer.reducer;
