/* eslint-disable import/prefer-default-export, no-shadow */

enum typeOfPayments {
  credit = 'CARTÃO DE CRÉDITO',
  accountDebit = 'DÉBITO EM CONTA',
  discountOnSheet = 'DESCONTO EM FOLHA',
}

enum discountOnSheetTypes {
  enrollment = 'MATRÍCULA',
  benefit = 'BENEFÍCIO',
}

enum proposalStatus {
  pendingSignature = 'PENDENTE ASSINATURA',
  signed = 'ASSINADO',
  canceled = 'CANCELADA',
  contractCanceledDefinitive = 'CONTRATO CANCELADO-DEFINITIVO',
}

enum proposalElectronicSignature {
  confia = 'CONFIA',
  clickSign = 'CLICKSIGN',
}
enum AnnouncementTypes {
  general = 'GERAL',
  news = 'NOVIDADES',
  message = 'MENSAGEM',
}
enum RelationshipAnnouncementStatuses {
  sent = 'ENVIADO',
  received = 'RECEBIDO',
  read = 'LIDO',
}

export {
  typeOfPayments,
  discountOnSheetTypes,
  proposalStatus,
  proposalElectronicSignature,
  RelationshipAnnouncementStatuses,
  AnnouncementTypes,
};
