import { Logout, Person } from '@mui/icons-material';
import {
  Avatar, Box, IconButton,
  ListItemIcon,
  Menu,
  MenuItem, Typography,
} from '@mui/material';
import NotificationsMenu from 'components/NotificationsMenu';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearGroupAndPermissions } from 'store/ducks/Groups';
import { clearSignin } from 'store/ducks/Signer';

const MenuOptions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => {
    return state.signer.signin;
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    await dispatch(clearSignin());
    dispatch(clearGroupAndPermissions());
    handleClose();
    navigate('/signin');
  };

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <NotificationsMenu />
      <Box sx={{ display: { md: 'flex' } }}>
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          // aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <Avatar variant="square" sx={{ borderRadius: '10px' }}>
            <Person />
          </Avatar>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ marginTop: '40px' }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <ListItemIcon>
              <Person fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography>{user}</Typography>
          </MenuItem>
          <MenuItem onClick={handleLogOut}>
            <ListItemIcon>
              <Logout fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography>Sair</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default MenuOptions;
