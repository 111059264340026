/* eslint-disable object-curly-newline */

import { createTheme } from '@mui/material/styles';

export const colors = {
  primary: '#121034',
  secondary: '#f2f5f9',
  icon: '#34598C',
  borderRadius: '0px 60.48px 0px 60.48px',
  tabs: '#1FA26D',
  tab: '#fff',
  button: '#2A2A5A',
};

const theme = createTheme({
  typography: {
    fontFamily: ['Gabarito', 'Open Sans', 'sans-serif'].join(','),
    h1: {
      fontFamily: 'Gabarito',
    },
    h2: {
      fontFamily: 'Gabarito',
    },
    h3: {
      fontFamily: 'Gabarito',
    },
    h4: {
      fontFamily: 'Gabarito',
    },
    h5: {
      fontFamily: 'Gabarito',
    },
    h6: {
      fontFamily: 'Gabarito',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.primary,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: colors.primary,
        },
      },
    },
    MuiAvatar: { styleOverrides: { root: { backgroundColor: colors.primary } } },
    MuiPaper: { styleOverrides: { root: { borderRadius: colors.borderRadius } } },
    MuiTabs: {
      styleOverrides: {
        indicator: { backgroundColor: colors.tabs, height: '45px', borderRadius: '15px' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: colors.tab,
            fontWeight: 'bold',
            zIndex: 1,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '18px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '18px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.primary,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: { root: { color: colors.icon } },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          color: colors.icon,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: colors.primary,
          '&:hover': {
            backgroundColor: colors.button,
          },
        },
        root: {
          borderRadius: '13px',
          fontSize: '0.875rem',
          fontWeight: 600,
        },
      },
    },
    MuiIcon: { styleOverrides: { colorPrimary: colors.icon } },
  },

  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    mode: 'light',
  },
});

export default theme;
