import React from 'react';
import clsx from 'clsx';
import { Box, SxProps } from '@mui/material';
import useStyles from './styles';

export type Icolor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'draft'
  | 'info'
  | 'approved'
  | 'waitApproved'
  | 'disapproved';

interface Props {
  children: React.ReactNode;
  color: Icolor;
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'full-width' | 'full-size-kana';
  sx?: SxProps;
}

export const Label = ({ children, color = 'secondary', textTransform = 'uppercase', sx }: Props) => {
  const classes = useStyles();
  return (
    <Box className={clsx([classes[color]])} sx={{ textTransform, ...sx }}>
      {children}
    </Box>
  );
};

export default Label;

Label.defaultProps = { textTransform: 'uppercase', sx: undefined };
