import { combineReducers } from '@reduxjs/toolkit';
import signerReducer from './ducks/Signer';
import groupsReducer from './ducks/Groups';
import userReducer from './ducks/User';
import proposalStatusReducer from './ducks/Proposal';
import productStatusReducer from './ducks/Product';
import reasonReducer from './ducks/Reason';
import customerReducer from './ducks/Customer';
import parametrizationReducer from './ducks/Parameterizations';
import filesStatusReducer from './ducks/Files';
import signaturesStatusReducer from './ducks/Signature';
import settingsReducer from './ducks/Settings';

const rootReducer = combineReducers({
  groups: groupsReducer,
  proposal: proposalStatusReducer,
  product: productStatusReducer,
  customer: customerReducer,
  files: filesStatusReducer,
  signatures: signaturesStatusReducer,
  parametrization: parametrizationReducer,
  signer: signerReducer,
  user: userReducer,
  settings: settingsReducer,
  reason: reasonReducer,
});

export default rootReducer;
