import { refreshTokenResponse } from 'interfaces/Signer';
import { clientToServerEvents, serverToClientEvents } from 'interfaces/Socket';
import api from 'services';
import { io, Socket } from 'socket.io-client';
import store from 'store';
import { setRefreshToken } from 'store/ducks/Signer';

const socket = io(String(process.env.REACT_APP_WS), {
  auth(cb) {
    const state = store.getState();
    cb({ token: state.signer.signin.token });
  },
}) as Socket<serverToClientEvents, clientToServerEvents>;

socket.on('connect_error', async (err) => {
  if (!(err instanceof Error) || err.message !== 'Token expirado') {
    setTimeout(() => {
      socket.connect();
    }, 2000);
    return;
  }

  const { currentRefreshToken } = store.getState().signer.signin;
  const res = await api.post<refreshTokenResponse>(
    '/refreshToken',
    { refreshToken: currentRefreshToken },
    {
      validateStatus: () => {
        return true;
      },
    },
  );
  if (res.status === 200) {
    api.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
    store.dispatch(setRefreshToken({ token: res.data.token, currentRefreshToken: res.data.currentRefreshToken }));
  }
});

export default socket;
