/* eslint-disable no-new */
import { useCallback, useEffect } from 'react';

const useBrowserNotification = () => {
  const notify = useCallback(async (title: string, options: NotificationOptions) => {
    if (!('Notification' in window)) {
      return;
    }

    if (Notification.permission === 'granted') {
      new Notification(title, options);
    } else if (Notification.permission !== 'denied') {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        new Notification(title, options);
      }
    }
  }, []);
  useEffect(() => {
    if (['granted', 'declined'].includes(Notification.permission)) {
      return;
    }
    Notification.requestPermission();
  }, []);
  return { notify };
};

export default useBrowserNotification;
